import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';

import api from 'services/Api/Api.js'
import SvgIcon from 'common/SvgIcon/SvgIcon.js'
import ApSelect from 'common/ApSelect/ApSelect.js'
import { ApAddon, ApInputStack, ApInput } from 'common/ApInput/ApInput'
import ApModal from 'common/ApModal/ApModal.js'
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import ApButton from 'common/ApButton/ApButton.js'

import '../Checkout.css'
import './Collections.css'

const Collect = (props) => {
    //Shameless copy-paste of checkout. Names may seem weird
    const [companyName, setCompanyName] = useState(null);
    const [vat, setVat] = useState(24);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [storage, _setStorage] = useState(null);
    const [items, _setItems] = useState([]);
    const [component, _setComponent] = useState('');
    const [amount, setAmount] = useState(1);
    const [actions, _setActions] = useState([]);
    const [profitSettings, _setProfitSettings] = useState(null);
    const [collectionId, setCollectionId] = useState(null);
    const [inputValue, _setInputValue] = useState(null);

    const [saleFinished, setSaleFinished] = useState(false);

    const [customer, setCustomer] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const [modifyItem, setModifyItem] = useState(null);
    const [modifyModalOpen, setModifyModalOpen] = useState(false);
    const [manualIndex, setManualIndex] = useState(1);
    let nextIndex =1;

    const inputRef = useRef(null);
    const inputValueRef = useRef(inputValue);
    const componentRef = useRef(component);
    const itemsRef = useRef(items);
    const profitSettingsRef = useRef(profitSettings);
    const actionsRef = useRef(actions);
    const storageRef = useRef(storage);
    const modalRef = useRef(null);
    const modifyModalRef = useRef(null);
    const correctInputRef = useRef(null);

    useEffect(() => {
        setLoading(true)
        api({
            method: 'get',
            url: props.type === 'consumables' ? 'consumables/settings':'checkout/settings',
        }).then(( response ) => {
            setCompanyName(response.company_name)
            setVat(response.default_vat ? response.default_vat : 24)
            setProfitSettings(response.profit_settings)

            if (props.manualStorageLocation !==undefined && Object.keys(props.manualStorageLocation).length > 0) {
                setStorage(props.manualStorageLocation)
            } else {
                setStorage(response.storage)
            }

            if (inputRef.current) {
                inputRef.current.focus();
            }

            setLoading(false)
        
        }).catch((error) => {
            console.log(error);
            setLoading(false)
            errorPopper(error, tr('get_error'));
        });
    }, [])

    useEffect(() => {
        if (props.collection) {
            setLoading(true)

            api({
                method: 'post',
                url: 'sales/collections/get/id',
                data: {
                    id: props.collection,
                    components: true
                }
            }).then((response) => {
                response.components.forEach((component) => {
                    component.count = Number(component.component_count)
                })
                setItems(response.components)
                
                setActions(actions.concat({
                    type: 'delete-all'
                }))

                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                errorPopper(error, tr('get_error'));
            })
        }
    }, [props.collection])

    useEffect(() => {
        function keyListener(event) {
            let found = false;
            let notCountInputs = [];
            if (props.notCountInputs) {
                notCountInputs = props.notCountInputs;
            }

            for (let element of notCountInputs) {
                // console.log(element);
                // console.log(document.activeElement);
                let id1 = element ? element.id : null;
                let id2 = document.activeElement ? document.activeElement.id : null;
                if (id1 === null || id2 === null) {
                    continue;
                }
                if (element.id === document.activeElement.id) {
                    // console.log(element.id, document.activeElement.id);
                    found = true;
                }
            }
            // console.log(found);
            if (!((modalRef.current && modalRef.current.props && modalRef.current.props.show) ||
               (modifyModalRef.current && modifyModalRef.current.props && modifyModalRef.current.props.show))) {
                
                if (inputRef.current) {
                    if (document.activeElement !== inputRef.current && !found) {
                        inputRef.current.focus();
                    }

                }
                if (event.key === 'Enter') {
                    addOnEnter();
                }
            }
        }

        const collectionsListener = window.addEventListener('keydown', keyListener)

        return () => {
            window.removeEventListener('keydown', keyListener)
        }
    }, [])

    const addOnEnter = async () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }

        const inputComponent = { ...component };
        const inputValue = correctInputRef.current.getValue();

        // if (inputValue=="") {
        //     return null;
        // }

        correctInputRef.current.handleClear();

        let hasOne
        if (itemsRef.current.length > 0) {
            hasOne = itemsRef.current.find(item => {
                let hasIdentifier = false
                if (item.identifiers) {
                    item.identifiers.forEach(id => {
                        if (id.value.includes(inputValue)) {
                            hasIdentifier = true
                        }
                    })
                }

                if (!hasIdentifier && item.scanned && item.name === inputValue) {
                    hasIdentifier = true
                }

                if (!hasIdentifier && !item.manual && item.scancode === inputValue) {
                    hasIdentifier = true
                }

                if (!hasIdentifier && 
                    item.manual && 
                    item.scancode &&
                    item.scancode === inputValue) {
                    hasIdentifier = true
                }

                if (hasIdentifier) {
                    return item
                }
            })
        } else {
            hasOne = false
        }

        const itemList = [...itemsRef.current]
        const isInNonFetched = itemList.find(item => item.scanned === true && item.name === inputValue)

        if (isInNonFetched) {
            //TODO: testaa toimivuus
            isInNonFetched.count = isInNonFetched.count + 1
            setItems(itemList)
            initComponentAdd()
            return
        }

        if (!hasOne && typeof inputValue === 'string' && inputValue !== null) {
            let rowId = getNextIndex();
            itemList.push({
                name: inputValue,
                scanned: true,
                price_sell: NaN,
                count: 1,
                scancode: inputValue,
                rowId: rowId,
            })
            setItems(itemList)
            fetchComponentDetails(itemList.length - 1, inputValue, rowId)
        } else if (!hasOne && typeof componentRef.current === 'object' && inputValue === null) {
            //manual enter
            add()
        } else {
            updateItemsList(hasOne)

            // const newActions = [...actionsRef.current]
            // setActions(newActions.concat({
            //     type: 'update',
            //     componentId: hasOne.id,
            //     amount: 1,
            //     rowId: hasOne.rowId
            // }))
        }

        initComponentAdd()     
    }

    const getNextIndex = () => {
        return nextIndex++;
    }


    const isRepeating = (str) => {
        if (str.length === 0) return false

        const found = []

        for (let i = 0; i <= str.length - 3; i++) {
            const searchTerm = str.substring(i, i + 3)
            
            for (let j = 0; j <= str.length - 3; j++) {
                const compareTerm = str.substring(j, j + 3)

                if (searchTerm === compareTerm) {
                    const hasOne = found.find((item) => item.term === searchTerm)

                    if (hasOne) {
                        hasOne.count += 1
                    } else {
                        found.push({term: searchTerm, count: 1})
                    }
                }
            }
        }

        let highestCount = {count: 0}

        found.forEach((item) => {
            if(item.count > highestCount.count && item.term !== '000') {
                highestCount = item
            }
        })
        
        if (highestCount.count <= 2) return false

        const splitted = str.split(highestCount.term)

        const finals = []

        splitted.forEach((item) => {
            if (item !== '0000') {
                if (item.includes('0000')) {
                    const newString = highestCount.term + item.replace('0000', '')
                    finals.push(newString)
                } else {
                    finals.push(highestCount.term + item)
                }
            }
        })

        if (finals.length > 0) return finals[0]

        return false
     };

    const fetchComponentDetails = async (index, searchTerm,rowId) => {
        let newSearchTerm = searchTerm

        if ((searchTerm.length === 13 || searchTerm.length === 11) && searchTerm[0] == 0) {
            let firstIndex
            for(let i = 0; i < searchTerm.length; i++) {
                if (searchTerm[i] != 0) {
                    firstIndex = i
                    break
                }
            }

            newSearchTerm = searchTerm.slice(firstIndex, searchTerm.length)
        }

        if (searchTerm.length > 20) {
            const repeating = isRepeating(searchTerm)
            if (repeating) {
                newSearchTerm = repeating
                searchTerm = repeating
            }
        }

        // console.log('DEBUGGING', searchTerm, newSearchTerm) //jätetään vielä siltä varalta, että ongelmia yhä esiintyy
        const response = await api({
            method: 'post',
            url: 'storage/components',
            data: {
                search: newSearchTerm,
                formatter: 'instance',
                filter_by_location_id: storageRef.current ? storageRef.current.id : false
            }
        })

        const updateList = [...itemsRef.current]
        index = updateList.findIndex(item => item.rowId === rowId);
        const newActions = [...actionsRef.current]

        // console.log('DEBUGGING_2', response) //jätetään vielä siltä varalta, että ongelmia yhä esiintyy

        if (response.length === 0) {
            updateList[index].notfound = true
            updateList[index].manual = true
            updateList[index].scanned = false
            updateList[index].scancode = searchTerm
            setItems(updateList)
            setActions(newActions.concat({
                type: 'new',
                
                amount: addedCount,
                rowId: rowId,
            }))
            return
        }

        
        // const newComponent = { ...response[0], count: 1 }
        let foundComponentIdIndex = updateList.findIndex(row => row.id === response[0].id);
        let foundComponent = updateList[foundComponentIdIndex] || null;
        let newComponent = {};
        let removeIndex = false;

        let addedCount = 1;
        if (foundComponent) {
            newComponent = {...foundComponent};
            if (foundComponent.rowId !== rowId) {
                newComponent.count += updateList[index].count;
                addedCount = updateList[index].count
                removeIndex = true;
            }
        }
        else {
            newComponent = { scancode: searchTerm, count: updateList[index].count, rowId: updateList[index].rowId, ...response[0] }
            addedCount = updateList[index].count;
        }


        
        if (removeIndex) {
            updateList[foundComponentIdIndex] = newComponent;
            updateList.splice(index, 1);
        }
        else {
            updateList[index] = newComponent;
        }



        setItems(updateList)

        
        if (removeIndex) {
            setActions(newActions.concat({
                type: 'update',
                componentId: newComponent.id,
                amount: addedCount,
                rowId: newComponent.rowId,
            }))
        }
        else {
            setActions(newActions.concat({
                type: 'new',
                componentId: newComponent.id,
                amount: addedCount,
                rowId: newComponent.rowId,
            }))
        }
        
    }

    const setComponent = data => {
        componentRef.current = data
        _setComponent(data)
    }

    const setItems = data => {
        itemsRef.current = data
        _setItems(data)
    }

    const setInputValue = data => {
        inputValueRef.current = data
        _setInputValue(data)
    }

    const setStorage = data => {
        storageRef.current = data
        _setStorage(data)
    }

    const setProfitSettings = data => {
        profitSettingsRef.current = data
        _setProfitSettings(data)
    }

    const setActions = data => {
        actionsRef.current = data
        _setActions(data)
    }

    const updateItemsList = (hasOne) => {
        const updatedComponent = {...hasOne}
            updatedComponent.count += amount

            const updatedItems = itemsRef.current.map((item) => {
                if (item.id === updatedComponent.id) {
                    return updatedComponent
                }
                return item
            })
            setItems(updatedItems)
            const newActions = [...actionsRef.current]
            setActions(newActions.concat({
                type: 'update',
                componentId: updatedComponent.id,
                amount: amount,
                rowId: updatedComponent.rowId,
            }))
    }

    const add = () => {
        const hasOne = itemsRef.current.find(item => item.id === componentRef.current.id)

        if (!hasOne) {
            const newComponent = {...componentRef.current, count: amount}
            const settings = profitSettingsRef.current

            if (settings.price_type === 'wholeseller') {
                newComponent.price_sell = newComponent.price

                if (settings.use_component_margin) {
                    newComponent.price_sell = newComponent.price * (1 + (newComponent.profit_percent / 100))
                }
            }

            if (settings.use_wholeseller_discount) {
                const withDiscount = newComponent.price_sell * ((100 - newComponent.wholeseller_discount_percent) / 100)
                newComponent.price_sell = withDiscount
            }

            if (settings.use_margin) {
                const withMargin = newComponent.price_sell * (1 + (settings.margin / 100))
                newComponent.price_sell = withMargin
            }

            const newItems = [...itemsRef.current]
            newItems.push(newComponent)
            setItems(newItems)

            const newActions = [...actionsRef.current]
            setActions(newActions.concat({
                type: 'new',
                componentId: newComponent.id,
                amount: amount,
                rowId: getNextIndex()
            }))
        } else {
            updateItemsList(hasOne)
        }
        initComponentAdd()
    }

    const initComponentAdd = () => {
        setComponent('')
        setAmount(1)
        setInputValue(null)

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const clearAll = () => {
        setLoading(false)
        setDisabled(false)
        setItems([])
        setComponent('')
        setAmount(1)
        setActions([])
        setSaleFinished(false)
        setCustomer(null)
        setOpenModal(false)
        setCollectionId(null)
        setInputValue(null)

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const printCollection = async () => {
        const response = await api({
            method: 'get',
            url: props.type === 'consumables' ? `consumables/collections/print/${collectionId}` :`sales/collections/print/${collectionId}`,
            responseType: 'blob'
        })

        const url = window.URL.createObjectURL( new Blob([ response ]) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${tr('collection')}_${collectionId}.pdf` );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const remove = () => {
        const oldActions = [...actions]
        const latestAction = oldActions[actions.length -1]

        if (!latestAction || !latestAction.type) return

        switch (latestAction.type) {
            case 'new':
                const oldItems = [...items]
                const removeitemIndex = latestAction.rowId ? items.findIndex(item => item.rowId === latestAction.rowId) : items.findIndex(item => item.id === latestAction.componentId)

                if (removeitemIndex!=-1) {
                    oldItems.splice(removeitemIndex,1)
                }
                else {
                    oldItems.pop()
                }
                setItems(oldItems)

                const oldActions = [...actions]
                oldActions.pop()
                setActions(oldActions)
                break
            case 'update':
                const reverseComponent = latestAction.rowId ? items.find(item => item.rowId === latestAction.rowId) :items.find(item => item.id === latestAction.componentId)
                reverseComponent.count -= latestAction.amount

                const updatedItems = items.map((item) => {
                    if (item.id === reverseComponent.id) {
                        return reverseComponent
                    }
                    return item
                })
                setItems(updatedItems)

                const oldAction = [...actions]
                oldAction.pop()
                setActions(oldAction)
                break
            case 'remove':
                const itemListToAdd = [...items]
                itemListToAdd.splice(latestAction.index, 0, latestAction.component)

                setItems(itemListToAdd)

                const oldActionRemove = [...actions]
                oldActionRemove.pop()
                setActions(oldActionRemove)
                break
            case 'update-by-modal':
                const component = items.find(item => item.id === latestAction.componentId)
                component.price_sell = latestAction.price
                component.count = latestAction.count

                const newListToUpdate = items.map((item) => {
                    if (item.id === latestAction.componentId) {
                        return component
                    }

                    return item
                })

                setItems(newListToUpdate)

                const oldActionUpdate = [...actions]
                oldActionUpdate.pop()
                setActions(oldActionUpdate)
                break
            case 'delete-all':
                setItems([])
                setActions([])
                if (props.clearCollection) props.clearCollection()
                break
            default:
                break
        }
        
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const modifyEntry = (item, index) => {
        const deepCopyItem = {...item}
        setModifyItem({item: deepCopyItem, index})
        setModifyModalOpen(true)
    }

    const removeItem = () => {
        const index = modifyItem.index
        const newItemList = [...items]
        const removedItem = {...newItemList[index]}
        newItemList.splice(index, 1)

        setItems(newItemList)
        setActions(actions.concat({
            type: 'remove',
            component: removedItem,
            index: index
        }))

        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const updateListWithManual = () => {
        setItems(items.concat({
            id: modifyItem.item.id + manualIndex,
            name: modifyItem.item.name,
            price_sell: Number(modifyItem.item.price_sell),
            count: Number(modifyItem.item.count),
            manual: true
        }))

        setActions(actions.concat({
            type: 'new-manual-item',
            componentId: modifyItem.item.id + manualIndex,
            price: Number(modifyItem.item.price_sell),
            count: Number(modifyItem.item.count)
        }))

        const newIndex = manualIndex + 1
        setManualIndex(newIndex)
        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const updateItem = () => {
        if (modifyItem.manual) {
            updateListWithManual()
            return
        }

        const index = modifyItem.index
        const newItemList = [...items]
        const oldItem = {...newItemList[index]}
        newItemList[index].price_sell = Number(modifyItem.item.price_sell)
        newItemList[index].count = Number(modifyItem.item.count)
        newItemList[index].name = modifyItem.item.name

        if (Object.keys(modifyItem.item).includes('notfound')) {
            newItemList[index].notfound = modifyItem.item.notfound
        }

        setItems(newItemList)
        setActions(actions.concat({
            type: 'update-by-modal',
            componentId: modifyItem.item.id,
            price: oldItem.price_sell,
            count: oldItem.count
        }))

        setModifyItem(null)
        setModifyModalOpen(false)
    }

    const addManualComponent = () => {
        setModifyItem({
            manual: true,
            item: {
                manual: true,
                id: 'new',
                name: '',
                count: '',
                price_sell: ''
            }
        })
        setModifyModalOpen(true)
    }

    const checkModalValidity = () => {
        if (modifyItem && modifyItem.item.manual) {
            return !modifyItem.item.count || !modifyItem.item.name
        }

        return modifyItem && !modifyItem.item.count
    }

    const saveCollection = async (isReturn = false) => {
        setLoading(true)

        if (props.type === 'storage-transfer' && Object.keys(props.receivingStorage).length === 0) {
            window.emitter.emit('popper', {
                content: <strong>{ tr('receiving_storage_cannot_be_empty') }</strong>,
                type: 'warning',
                time: 4500,
            });
            setLoading(false)
            return
        }

        let type = 'checkout'

        if (props.type) {
            type = props.type
        }

        if (isReturn) {
            type += '-return'
        }

        const data = {
            type: type,
            components: items,
            description: customer,
            receivingStorage: props.receivingStorage ? props.receivingStorage : false,
            isReturn: isReturn
        }

        if (props.type === 'consumables') {
            data.selected_storage_id = props.manualStorageLocation ? props.manualStorageLocation.id : null;
         }
       
        api({
            method: 'post',
            url: props.type === 'consumables' ? 'consumables/collections/save':'sales/collections/save',
            data: data
        }).then((response) => {
            setCollectionId(response);
            props.refreshList(!props.refresh)

            if (props.storageTransfer && isReturn) {
                props.storageTransfer(items, isReturn)
            }
            else if (props.storageTransfer) {
                props.storageTransfer(items)
            }
            
            setSaleFinished(true)
            

            if (isReturn) {
                setOpenModal(false)
                clearAll()
            }
        
            setLoading(false)
            if (props.clearCollection) props.clearCollection()
        }).catch((error) => {
            setLoading(false)
            console.log('virhe:', error)
            errorPopper(error, tr('save_error'));
        })   
    }
 
    const renderBody = () => {
        const payButtonDisabled = () => {
            if (items.length <= 0 || disabled) {
                return 'disabled'
            }

            const notFound = items.find(item => item.notfound && item.notfound === true)
            if (notFound) {
                return 'disabled'
            }
        }

        if (!storage && !props.storageTransfer) {
            return (
                <div className="padding">
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                        <SvgIcon icon="cubes" type="solid" style={{marginRight: '0.6em', marginBottom: '0.25em'}}/>
                        <h4>{ tr('storage_not_set') }</h4>
                    </div>
                    <div>{ tr('storage_not_set_info') }</div>
                </div>
            )
        }

        return (
            <div className="padding checkoutContainer">
                <div className="checkout-left-panel">
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.8em'}}>
                            <div className={`checkout-reverse-button ${items.length <= 0 || disabled ? ' disabled' : ''}`} 
                                 onClick={items.length > 0 ? () => remove() : undefined}
                            >
                                <SvgIcon icon="backspace" type="solid"/>
                                <p style={{marginLeft: '5px'}}>{ tr('back') }</p>
                            </div>

                            <div className={`checkout-add-button ${!component || disabled ? ' disabled' : ''}`} 
                                 onClick={component ? () => add() : undefined}
                            >
                                <SvgIcon icon="plus" type="solid"/>
                                <p style={{marginLeft: '5px'}}>{ tr('add') }</p>
                            </div>
                        </div>

                        <ApInputStack gap='0'>
                            <ApSelect
                                label={ tr('search_storage_component') }
                                loading={ loading }
                                value={component}
                                // optionRenderer={ this.dropDownOption }
                                optionRenderer="component_detail"
                                onChange={ (e) => {
                                    // if (componentRef.current !== '' && componentRef !== e) {
                                    //     setAmount(1)
                                    // }
                                    
                                    setComponent(e)
                                } }
                                objKeyId="id"
                                objKeyValue="name"
                                apiUrl="storage/components"
                                clearable
                                clearOnSelect
                                // customChangeHandler={(e) => {
                                //     setInputValue(e)
                                // }}
                                customRef={inputRef}
                                ref={correctInputRef}
                                apiData={{
                                    formatter: 'instance',
                                    filter_by_location_id: props.manualStorageLocation ? props.manualStorageLocation.id : false
                                }}
                            />
                            <ApInput
                                width='70'
                                type="number"
                                name="amount"
                                id="amount"
                                align="right"
                                value={ amount }
                                onChange={ ( e ) => { 
                                    setAmount(e.target.value > 0 ? Number(e.target.value) : 1) 
                                } }
                                loading={ loading }
                            />
                            <ApAddon width="50" noLeftBorder> {component  && component.unit ? component.unit : tr('pcs')} </ApAddon>
                        </ApInputStack>
                    </div>

                </div>
                <div className='checkout-right-panel' style={{width: '450px', maxWidth: '550px'}}>
                    <div className='apList apList-no-height-restrict' style={{fontFamily: 'Roboto-Mono'}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <h1 className='collections-header-container'>{ tr('collection_list') } {props.collection ? `(${props.collection})` : ''}</h1>
                        </div>
                        <hr />
                        <div>
                            <div key={`checkout-list-header`} className='collections-item-row header-row'>
                                <div style={{paddingRight: '5px'}}>{ tr('product') }</div>
                                <div className='receipt-row-item'>{ tr('pcs') }</div>
                            </div>
                            <hr />

                            {items.map((item, index) => 
                                { 
                                    if (item.scanned) {
                                        return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='collections-item-row'>
                                            <div style={{paddingRight: '5px'}}>{item.name}</div>
                                            <div className='receipt-row-item'>{Number(item.count)}</div>
                                            <div className='receipt-row-item'><div className="apInlineLoader" /></div>
                                        </div>
                                    }

                                    if(item.notfound) {
                                        return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='collections-item-row checkout-not-found'>
                                            <div style={{paddingRight: '5px'}}>{ tr('product_not_found') }</div>
                                            <div className='receipt-row-item'>{Number(item.count)}</div>
                                        </div>
                                    }
                                
                                    return <div key={`checkout-list-${index}`}  onClick={() => modifyEntry(item, index)} className='collections-item-row'>
                                        <div style={{paddingRight: '5px'}}>{item.name}</div>
                                        <div className='receipt-row-item'>{Number(item.count)}</div>
                                    </div>
                                }
                            )}
                        </div>
                    </div>

                    <div className='pay-button collections-add-manual-button'
                        onClick={() => addManualComponent()}>
                        { tr('add_manual') }
                    </div>
                    <div className={`pay-button ${payButtonDisabled()}`}
                        onClick={items.length > 0 ? () => {
                            setOpenModal(true)
                            setDisabled(true)
                        } : undefined}
                    >
                        <SvgIcon icon="dolly-flatbed" type="solid"/>
                        <p style={{marginLeft: '5px'}}>{props.manualStorageLocation ? tr('save_collection_or_restore') : tr('save_collection')}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>   
            {companyName ? renderBody() : <div className="apLoader" />}

            <Modal
                ref={modalRef}
				show={openModal}
				backdrop="static"
				bsSize="large"
			>
                <Modal.Header>
					<Modal.Title>
                        {saleFinished ?  
                            tr('sent') :
                            `${tr('save_collection')}?`
                        }
					</Modal.Title>
				</Modal.Header>

                {
                    loading ? 
                    <Modal.Body><div className="apLoader" /></Modal.Body> :
                    <Modal.Body>
                        {saleFinished ? 
                        <div>{ `${tr('collection')}-ID` }: {collectionId}</div> :
                        <ApInput
                            type='textarea'
                            label={ tr('comment') }
                            name="checkout_customer"
                            id="checkout_customer"
                            align="left"
                            value={ customer }
                            onChange={ ( e ) => setCustomer(e.target.value)}
                            loading={ loading }
                            disabled={loading}
                        />
                        }
                    </Modal.Body>
                }

                <Modal.Footer>
                    {saleFinished ? 
                        <>  
                            <ApButton 
                                color="green" 
                                onClick={() => {
                                    printCollection()
                                }}
                            >
                                { tr('print') }
                            </ApButton>
                            {' '}
                            <ApButton 
                                color="green" 
                                onClick={() => {
                                    clearAll()
                                }}
                            >
                                { tr('start_new_collection') }
                            </ApButton>
                        </>  :  
                        <div>
                            <ApButton 
                                color="blue"
                                disabled={loading} 
                                onClick={() => {
                                    setOpenModal(false)
                                    setDisabled(false)

                                    if (inputRef.current) {
                                        inputRef.current.focus();
                                    }
                                }}
                            >
                                { tr('cancel') }
                            </ApButton>
                            {' '}
                            {props.type === 'storage-transfer' && 
                            <ApButton 
                                color="green" 
                                onClick={() => saveCollection(true)}
                                loading={loading}
                                disabled={loading}>
                                { tr('save_restore') }
                            </ApButton>}
                            
                            {' '}
                            <ApButton 
                                color="green" 
                                onClick={() => saveCollection()}
                                loading={loading}
                                disabled={loading}
                            >
                                { tr('save_collection') }
                            </ApButton>
                        </div>
                    }
                </Modal.Footer>
            </Modal>

            <ApModal
                ref={modifyModalRef}
                show={modifyModalOpen}
                handleClose={ () => {
                    setModifyItem(null)
                    setModifyModalOpen(false)
                } }
                className="narrow overflow"

                header={
                    <div className="padding">
                        {modifyItem && modifyItem.item.manual ?
                            <span>{ tr('add_manual_collection_product') }</span>
                            : <span>{ tr('edit_collection_product') } {modifyItem ? `(${modifyItem.item.name})` : ''}</span>
                        }
                        
                    </div>
                }
                body={ <div className="padding">
                        {modifyItem && modifyItem.item.manual && <ApInput
                            type="text"
                            id="name"
                            name="name"
                            label={ tr('name') }
                            required
                            value={  modifyItem ? modifyItem.item.name : '' }
                            onChange={ (e) => {
                                const newItem = {...modifyItem}
                                newItem.item.name = e.target.value

                                if (Object.keys(newItem.item).includes('notfound')) {
                                    newItem.item.notfound = false
                                }

                                setModifyItem(newItem)
                            } }
                        />}
                        <ApInput
                            type="number"
                            id="count"
                            name="count"
                            label={ tr('amount') }
                            required
                            value={  modifyItem ? modifyItem.item.count : '' }
                            onChange={ (e) => {
                                const newItem = {...modifyItem}
                                newItem.item.count = e.target.value

                                if (Object.keys(newItem.item).includes('notfound')) {
                                    newItem.item.notfound = false
                                }

                                setModifyItem(newItem)
                            } }
                        />
                </div> }
                footer={ <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '1em', marginRight: '1em'}}>
                    <ApButton onClick={ () => {
                        setModifyItem(null)
                        setModifyModalOpen(false)
                    } }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>
                    <ApButton color="red" onClick={ () => removeItem() }>
                        <SvgIcon icon="trash-alt" type="regular" />
                        { tr('delete') }
                    </ApButton>

                    <ApButton color="green" 
                        onClick={ () => updateItem() }
                        disabled={checkModalValidity()}
                    >
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

                </div> }
            />
        </>
    )
}

export default Collect